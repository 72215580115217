import React from 'react'
import {
  Text,
  RichText,
  Image,
  File,
  Repeater,
  types,
} from 'react-bricks/frontend'
import blockNames from '../../blockNames'
import EditorBrickContainer from '../../shared/components/EditorBrickContainer'
import * as style from 'css/TeamMemberContent.module.css'
import Container from '../../shared/components/Container'
import { TeamMemberContentDefaultProps } from '../../defaultProps'

export interface TeamMemberContentProps {
  title: string
  role: string
  text: any
  profileImage?: types.IImageSource
}

const TeamMemberContent: types.Brick<TeamMemberContentProps> = ({
  profileImage,
}) => {
  return (
    <EditorBrickContainer>
      <Container>
        <Image
          propName="profileImage"
          alt={profileImage?.alt}
          maxWidth={300}
          aspectRatio={1}
          imageClassName={style.profileImage}
        />
        <div className={style.contentContainer}>
          <hgroup>
            <Text
              propName="title"
              placeholder="Enter a team member's name..."
              renderBlock={({ children }) => {
                return <h1>{children}</h1>
              }}
            />
            <Text
              propName="role"
              placeholder="Enter a team member's role..."
              renderBlock={({ children }) => {
                return <p>{children}</p>
              }}
            />
          </hgroup>
          <RichText
            propName="text"
            placeholder="Enter a team member's bio..."
            allowedFeatures={[
              types.RichTextFeatures.Bold,
              types.RichTextFeatures.Italic,
              types.RichTextFeatures.Link,
            ]}
          />
        </div>
      </Container>
    </EditorBrickContainer>
  )
}

TeamMemberContent.schema = {
  name: blockNames.TeamMemberContent,
  label: 'Team Member Content',
  category: 'Team Members',
  previewImageUrl: `/bricks/${blockNames.TeamMemberContent}.png`,
  // tags: [],

  // Defaults when a new brick is added
  getDefaultProps: () => TeamMemberContentDefaultProps,

  // Sidebar Edit controls for props
  sideEditProps: [
    {
      name: 'profileImage',
      type: types.SideEditPropType.Image,
      label: 'Profile Image',
    },
  ],
}

export default TeamMemberContent
