import classNames from 'classnames'
import React from 'react'
import * as style from 'css/Section.module.css'
import { BackgroundColorType } from '../../sideEditProps'
export interface SectionProps {
  children?: React.ReactNode
  className?: string
  container?: boolean
  paddingTop?: 'none' | 'small' | 'medium' | 'large' | 'xlarge'
  paddingBottom?: 'none' | 'small' | 'medium' | 'large' | 'xlarge'
  backgroundColor?: BackgroundColorType
}

export default function Section({
  children,
  className,
  container,
  paddingTop,
  paddingBottom,
  backgroundColor,
}: SectionProps) {
  const baseClassName = classNames(style.section, {
    [style.paddingTopNone]: paddingTop === 'none',
    [style.paddingTopSmall]: paddingTop === 'small',
    [style.paddingTopMedium]: paddingTop === 'medium',
    [style.paddingTopLarge]: paddingTop === 'large',
    [style.paddingTopXlarge]: paddingTop === 'xlarge',
    [style.paddingBottomNone]: paddingBottom === 'none',
    [style.paddingBottomSmall]: paddingBottom === 'small',
    [style.paddingBottomMedium]: paddingBottom === 'medium',
    [style.paddingBottomLarge]: paddingBottom === 'large',
    [style.paddingBottomXlarge]: paddingBottom === 'xlarge',
  })

  if (backgroundColor?.className) {
    return (
      <section
        className={classNames(baseClassName, backgroundColor?.className)}
      >
        <div
          className={classNames(
            {
              container: container,
            },
            className
          )}
        >
          {children}
        </div>
      </section>
    )
  } else {
    return (
      <section
        className={classNames(
          baseClassName,
          {
            container: container,
          },
          className
        )}
      >
        {children}
      </section>
    )
  }
}
