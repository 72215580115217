import React from 'react'
import { Text, RichText, types, Plain } from 'react-bricks/frontend'
import blockNames from '../blockNames'
import EditorBrickContainer from '../shared/components/EditorBrickContainer'
import Section, { SectionProps } from '../shared/components/Section'
import { sectionLayoutSideGroup, BackgroundColorType } from '../sideEditProps'
import Grid from '../shared/components/Grid'
import * as style from 'css/TextForm.module.css'
import classNames from 'classnames'
import { TextFormDefaultProps } from '../defaultProps'

export interface TextFormProps {
  title: string
  subtitle: string
  text: any
  paddingTop: SectionProps['paddingTop']
  paddingBottom: SectionProps['paddingBottom']
  formPosition: 'left' | 'right'
  formSubmitButtonText?: string
  backgroundColor?: BackgroundColorType
}

const TextForm: types.Brick<TextFormProps> = ({
  paddingTop,
  paddingBottom,
  formPosition = 'right',
  formSubmitButtonText,
  backgroundColor,
}) => {
  return (
    <EditorBrickContainer>
      <Section container paddingTop={paddingTop} paddingBottom={paddingBottom} backgroundColor={backgroundColor}>
        <Grid className={style.mainGrid}>
          <div
            className={classNames(style.mainGridTextItem, {
              [style.order2]: formPosition === 'left',
              [style.order1]: formPosition === 'right',
            })}
          >
            <hgroup>
              <Text
                propName="title"
                renderBlock={({ children }) => <h2>{children}</h2>}
              />
              <Text
                propName="subtitle"
                renderBlock={({ children }) => <p>{children}</p>}
              />
            </hgroup>
            <RichText
              propName="text"
              allowedFeatures={[
                types.RichTextFeatures.Bold,
                types.RichTextFeatures.Link,
                types.RichTextFeatures.OrderedList,
                types.RichTextFeatures.UnorderedList,
                types.RichTextFeatures.Heading3,
                types.RichTextFeatures.Heading4,
                types.RichTextFeatures.Heading5,
                types.RichTextFeatures.Heading6,
              ]}
            />
          </div>
          <div
            className={classNames(style.mainGridFormItem, {
              [style.order1]: formPosition === 'left',
              [style.order2]: formPosition === 'right',
            })}
          >
            <article className={style.formCard}>
              <form name="contact" method="post" data-netlify="true">
                <fieldset className={classNames(style.fieldset)}>
                  <label className={style.fullWidthField}>
                    Property Address for Sale{' '}
                    <span className={style.required}>*</span>
                    <input
                      name="address"
                      placeholder="Property Address"
                      autoComplete="address"
                      required
                    />
                  </label>
                  <label>
                    Name <span className={style.required}>*</span>
                    <input
                      name="name"
                      placeholder="Your Name"
                      autoComplete="name"
                      required
                    />
                  </label>
                  <label>
                    Phone <span className={style.required}>*</span>
                    <input
                      id="contact-phone"
                      name="phone"
                      placeholder="Your Phone"
                      autoComplete="tel"
                      pattern="[0-9]{10}"
                      required
                    />
                  </label>

                  <label>
                    Email <span className={style.required}>*</span>
                    <input
                      type="email"
                      name="email"
                      placeholder="Your Email"
                      autoComplete="email"
                      required
                    />
                  </label>
                </fieldset>
                <input type="hidden" name="form-name" value="contact" />
                <button type="submit">
                  {formSubmitButtonText || 'Submit'}
                </button>
              </form>
            </article>
          </div>
        </Grid>
      </Section>
    </EditorBrickContainer>
  )
}

TextForm.schema = {
  name: blockNames.TextForm,
  label: 'TextForm',
  category: 'Forms',
  tags: ['Text', 'Form'],
  previewImageUrl: `/bricks/${blockNames.TextForm}.png`,
  getDefaultProps: () => TextFormDefaultProps,
  sideEditProps: [
    sectionLayoutSideGroup,
    {
      name: 'formPosition',
      label: 'Form Position',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Radio,
        options: [
          { value: 'left', label: 'Left' },
          { value: 'right', label: 'Right' },
        ],
      },
    },
    {
      name: 'formSubmitButtonText',
      label: 'Form Submit Button Text',
      type: types.SideEditPropType.Text,
    },
    // {
    //   // relation to form enitities
    //   name: 'form',
    //   label: 'Form',
    //   type: types.SideEditPropType.Relationship,
    //   relationshipOptions: {
    //     references: blockNames.Form,
    //     multiple: false,
    //     embedValues: true,
    //     label: 'Select a form',
    //   },
    // },
  ],
}

export default TextForm
