import { types } from 'react-bricks/frontend'
import blockNames from './bricks/pico-ui/blockNames'

const pageTypes: types.IPageType[] = [
  {
    name: 'page',
    pluralName: 'pages',
    defaultLocked: false,
    defaultStatus: types.PageStatus.Published,
    excludedBlockTypes: [
      blockNames.Footer,
      blockNames.Header,
      blockNames.TeamMemberContent,
    ],
    getDefaultContent: () => [],
  },
  // {
  //   name: 'blog',
  //   pluralName: 'Blog',
  //   defaultLocked: false,
  //   defaultStatus: types.PageStatus.Published,
  //   getDefaultContent: () => [],
  //   allowedBlockTypes: [
  //     'title',
  //     'paragraph',
  //     'big-image',
  //     'video',
  //     'code',
  //     'tweet',
  //     'tweet-light',
  //     'blog-title',
  //     'newsletter-subscribe',
  //   ],
  // },
  {
    name: 'layout',
    pluralName: 'layout',
    defaultStatus: types.PageStatus.Published,
    getDefaultContent: () => [],
    isEntity: true,
  },
  // {
  //   name: 'form',
  //   pluralName: 'forms',
  //   defaultLocked: false,
  //   defaultStatus: types.PageStatus.Published,
  //   isEntity: true,
  //   allowedBlockTypes: ['form'],
  // },
  // {
  //   name: 'teamMember',
  //   pluralName: 'Team Members',
  //   defaultLocked: true,
  //   defaultStatus: types.PageStatus.Published,
  //   allowedBlockTypes: [blockNames.TeamMemberContent],
  //   getDefaultContent: () => [blockNames.TeamMemberContent],
  //   slugPrefix: {
  //     default: 'team/',
  //   },
  // },
]

export default pageTypes
