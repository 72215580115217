import React from 'react'
import classNames from 'classnames'

interface FlexSpecificProps {
  flexFlow?: 'row' | 'column'
  justifyContent?:
    | 'flex-start'
    | 'center'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
  alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch'
  flexWrap?: 'wrap' | 'nowrap'
}

export interface FlexProps extends FlexSpecificProps {
  children: React.ReactNode
  className?: string
}

export default function Flex(props: FlexProps) {
  const flexOptions = {
    display: 'flex',
    flexFlow: props.flexFlow,
    justifyContent: props.justifyContent,
    alignItems: props.alignItems,
    flexWrap: props.flexWrap,
  }

  return (
    <div className={classNames(props.className)} style={flexOptions}>
      {props.children}
    </div>
  )
}
