import classNames from 'classnames'
import React from 'react'

interface GridProps {
  children: React.ReactNode
  className?: string
}

export default function Grid({ children, className }: GridProps) {
  return <div className={classNames(className, 'grid')}>{children}</div>
}
